<template>
  <v-card
    elevation="0"
    :loading="loaders.loading"
    class="mx-auto "
    style=""
    max-width="1200"
  >
    <v-img :src="participant.cover"
           max-height="300"
           :lazy-src="require(`@/assets/images/avatars/logo.png`)"
           gradient="to bottom, rgba(0,0,0,.0), rgba(0,0,0,.5)"
           class="white--text align-end"
    >
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0 "
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
      <v-card-title class="pa-1 bg-gradient-primary" v-if="participant.id" >
        <v-avatar
          size="50"
          class="ma-0 mr-3 av-active"
        >
          <v-img
            v-if="participant.user.avatar"
            alt="Avatar"
            contain
            :src="require(`@/assets/images/avatars/${participant.user.avatar}`)"
          />
          <v-img
            v-else
            alt="Avatar"
            contain
            :src="require(`@/assets/images/avatars/logo.png`)"
          />
        </v-avatar>
        {{participant.user.username}}
      </v-card-title>
      <v-card-actions v-if="participant.id" >
        <h3 style="color: whitesmoke!important;" class="text--white">{{participant.title}}</h3>
        <v-spacer></v-spacer>
        <v-btn rounded class="pr-3" color="primary" :to="'/contest/'+participant.id+'/enroll'" >Previsualiser </v-btn>
        <v-btn rounded :disabled="!participant.pages.length" color="primary" :to="'/scan/one-shot/'+participant.chapter.id" >Lire le Oneshot</v-btn>
      </v-card-actions>
    </v-img>

    <template v-if="participant.id && participant.user.id == user.id" >

      <v-card  flat>
        <v-tabs class=" position-sticky font-secondary"
                v-model="panel" center-active
                align-tabs="center"
                grow
        >
          <v-tab
            key="illustrations"
          >
            Informations Detaillees
          </v-tab>
          <v-tab
            key="oneshot"
          >
            Charger les Images (max 1Mo/images)
          </v-tab>
        </v-tabs>

        <template>

          <v-tabs-items v-model="panel">
            <v-tab-item
              key="illustrations"
            >

              <v-card-text  >
                <v-alert
                  color="blue"
                  type="warning"
                >
                  Renseignez les informations relatives à votre travail et enregistrez. Vous pourrez
                  les
                  modifier a tout moment tant que le concours est toujours en cours.
                </v-alert>
              </v-card-text>

              <v-card-text  >
                <v-row>
                  <v-col cols="12" lg="4" md="4">

                    <v-file-input dense outlined v-model="cover" label="Choisir une image de couverture"
                    ></v-file-input>
                    <template v-if="participant.cover">
                      <v-img class="mt-6" contain height="300" :src="participant.cover"></v-img>
                    </template>

                  </v-col>
                  <v-col cols="12" lg="8" md="8">

                    <v-row>
                      <v-col cols="12">
                        <v-select
                          :loading="loaders.categories"
                          v-model="participant.category_id"
                          outlined
                          dense
                          :items="categories"
                          label="Categorie"
                          item-value="id"
                          item-text="name"
                          class="pa-0"
                        >
                          <template v-slot:selection="data">
                            {{ data.item.name['fr'] }}
                          </template>
                          <template v-slot:item="data">
                            <v-list-item-content>
                              {{ data.item.name['fr'] }}
                            </v-list-item-content>
                          </template>
                        </v-select></v-col>
                      <v-col cols="12">
                        <label for="">Sens de lecture </label>
                        <v-radio-group v-model="participant.readingdirection">
                          <v-radio label="De la gauche vers la droite" :value="0"></v-radio>
                          <v-radio  label="De la droite vers la gauche" :value="1"></v-radio>
                          <v-radio  label="Du haut vers le bas" :value="2"></v-radio>
                        </v-radio-group></v-col>
                      <v-col cols="12">
                        <v-text-field class="pa-0" dense outlined v-model="participant.title" label="Titre"></v-text-field></v-col>
                      <v-col cols="12">
                        <v-textarea class="pa-0" dense outlined v-model="participant.synopsis" label="Synopsis"></v-textarea></v-col>
                    </v-row>

                  </v-col>
                </v-row>

              </v-card-text>

              <v-card-actions v-if="contest.active" >
                <v-spacer></v-spacer>
                <v-btn color="primary" :loading="loaders.update" @click="updateWork">
                  Enregistrez les modifications
                </v-btn>
              </v-card-actions>

            </v-tab-item>
            <v-tab-item
              :key="'series'"
            >
              <v-card-text class="pa-0 pa-md-3 pa-lg-6" >
                <v-alert
                  color="blue"
                  type="warning"
                >
                  Dimension pour les pages la largeur doit etre comprise entre 800px et 1200px
                  pour les doubles pages entre 1500 et 1800 pour un chargement optimal
                </v-alert>

                <chapter-form-component v-if="participant.id"
                                        :editable="contest.active"
                                        :chapter="participant.chapter"
                ></chapter-form-component>

              </v-card-text>

            </v-tab-item>

          </v-tabs-items>
        </template>


        <v-alert v-if="participant.status" color="success"
                 type="success"
        >
          Votre travail a bien ete envoye Merci pour votre participation et bonne chance :D
        </v-alert>
        <v-card-actions v-if="contest.active" class="mt-3" >
          <v-btn color="primary" v-if="participant.id" :loading="loaders.update"
                 block @click="submitWork"
          >
            Soumettre mon travail
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-dialog
        v-model="dialog.submitted"
        max-width="600"
      >
        <v-card
          color="success"
          elevation="0"
        >
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              text
              @click="dialog.submitted = false"
            >
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-title>BRAVO!! Votre travail a bien ete soumis.</v-card-title>
          <v-card-text>Vous venez de soumettre votre projet l'equipe en charge des évaluations
            commencera la lecture et la notation
          </v-card-text>
          <v-card-text>Vous recevrez un email ou un SMS avec le lien de lecture en ligne de
            votre
            travail.
            Invitez vos amis a creer leur compte sur la plateforme, a liker et commenter votre
            travail. le vote du publique comptera pour 40%
          </v-card-text>
          <v-card-actions>
            <v-btn
              small
              text
              @click="dialog.snack = false"
            >
              Fermer
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-dialog>

    </template>

  </v-card>
</template>

<script>
import {
  mdiFacebook,
  mdiChevronLeft,
  mdiClose,
} from '@mdi/js'
import { useRouter } from '@/utils'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import LoginComponent from '@/components/LoginComponent'
import CommentComponent from '@/components/CommentComponent'
import PostReplyFormComponent from '@/components/PostReplyFormComponent.vue'
import PostFormComponent from '@/components/PostFormComponent.vue'
import ChapterFormComponent from "@/components/ChapterFormComponent.vue";
import ReadMore from "@/components/ReadMore.vue";
import ComicbookCreatorActivityComponent
  from "@/components/comicbook/ComicbookCreatorActivityComponent.vue";

export default {
  name: 'Contest',
  components: {
    ComicbookCreatorActivityComponent,
    ReadMore,
    ChapterFormComponent,
    PostFormComponent,
    PostReplyFormComponent,
    CommentComponent,
    LoginComponent
  },
  setup() {

    const user_id = ref(localStorage.getItem('user_id'))
    const { router } = useRouter()
    const route = router.currentRoute
    const { id } = route.params
    const user = Drequest.getUser()
    const loaders = ref({
      loading: true,
      enroll: false,
      update: false,
      replypost: false,
      cardload: true,
      categories: true,
    })
    const dialog = ref({
      authentication: false,
      submitted: false,
      newenroll: false,
    })
    const post = ref({})
    const contest = ref({ cmstext: {}, post:{postimages:[]} })
    const participant = ref({})
    const participants = ref([])
    const categories = ref([])
    const participation = ref({})
    const cover = ref(null)
    const panel = ref(0)
    const e1 = ref(1)

    var url = 'detail.participant?jsonmodel=detail&id=' + id

    Drequest.api(url)
      .get(response => {
        console.log(response)
        contest.value = response.participant.contest
        participant.value = response.participant
        loaders.value.loading = false
      })
    Drequest.api('lazyloading.tree-item?dfilters=on&tree.name:eq=category')
      .get(response => {
        console.log(response)
        categories.value = response.listEntity
        loaders.value.categories = false
      })

    const submitWork = () => {

      if (!confirm('En soumettant votre travail vous ne pourrez plus modifier. Est ce que vous confirmez la soumission?')) {
        return 0
      }
      updateWork(1)
    }

    const updateWork = (status) => {
      if (!participant.value.title) {
        alert('Vous devez au moins renseigner le titre!')
        return 0
      }
      var fd = new FormData()
      fd.append('title', participant.value.title)
      fd.append('synopsis', participant.value.synopsis)
      fd.append('category_id', participant.value.category_id)
      fd.append('readingdirection', participant.value.readingdirection)
      if (status) {
        fd.append('status', 1)
      }else if(!participant.value.status)
        fd.append('status', '')

      if (cover.value) {
        console.log(cover.value)
        fd.append('cover', cover.value)
      }

      loaders.value.update = true
      Drequest.api('participant.update?id=' + participant.value.id)
        .data(fd)
        .post((response) => {
          loaders.value.update = false
          console.log(response)
          participant.value.cover = response.participant.cover
          cover.value = null
          console.log(status)
          if (status === 1) {
            dialog.value.submitted = true
          }
        })
    }


    const posted = post => {
      console.log(post)
      loaders.value.replypost = false
      //children.value.unshift(post)
      alert('Post publie')
    }
    const replyPost = () => {
      console.log(contest.value.post)
      post.value = contest.value.post
      loaders.value.replypost = true
    }
    const cancelpost = () => {
      loaders.value.replypost = false
    }
    return {
      updateWork,
      submitWork,
      posted,
      replyPost,
      cancelpost,

      user_id,
      user,
      post,
      contest,
      participation,
      participant,
      participants,
      categories,
      cover,
      loaders,
      dialog,
      panel,
      e1,
      icons: {
        mdiClose,
        mdiChevronLeft,
      }
    }
  }
}
</script>

<style scoped>

</style>
